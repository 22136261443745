import axios from 'axios'

export async function localizarPorCep (cep) {
    const url = `https://viacep.com.br/ws/${cep}/json/`

    return axios.get(url)
        .then(response => ({
            ...response.data,
            cidade: response.data.localidade
        }))
        .catch(() => ({}))

}

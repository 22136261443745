import store from '@/store'

const baseUrl = 'https://beta.paineldocorretor.com.br'

export async function redirectToBeta () {
    // await redirectTrial()
    await redirectUrl()
}

// async function redirectTrial () {
//     if (!location.search.includes('?event=trial-iniciado'))
//         return

//     const user = await store.dispatch('conta/USUARIO_RECUPERAR')

//     if (!isBetaTester(user))
//         return

//     const nome = user.nome.split(' ')[0]
//     const data = uriEncode({ contato: { nome: `Primeira cotação de ${nome}` } })

//     location.replace(`${baseUrl}/cotacoes/nova?data=${data}`)
// }

async function redirectUrl () {
    const redirect = map.find(p => location.pathname.match(p.pattern))

    if (!redirect || location.search.includes('skipBeta'))
        return

    const user = await store.dispatch('conta/USUARIO_RECUPERAR')

    if (!isBetaTester(user))
        return

    let path = redirect.to

    if (path.includes('$')) {
        const source = location.pathname + location.search
        path = source.replace(redirect.pattern, redirect.to)
    }

    location.replace(path)
}

function isBetaTester (user) {
    return user.corretoraPreferencias.betaTester
}

const map = [
    {
        pattern: /^\/produtos(\/?)/,
        to: `${baseUrl}/produtos`
    },
    {
        pattern: /^\/cotacoes(\/?)$/,
        to: `${baseUrl}/cotacoes?periodo=30`
    },
    {
        pattern: /^\/cotacoes\/nova(.*)$/,
        to: `${baseUrl}/cotacoes/nova$1`
    },
    {
        pattern: /^\/cotacoes\/([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})$/,
        to: `${baseUrl}/cotacoes/$1/edit`
    },
]